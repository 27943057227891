import React, { FunctionComponent } from "react";
import { Card } from "react-bootstrap";
import sanityImageUrlBuilder from "../../utils/imageUrlBuilder";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { Link } from "../Link";
import Picture from "../Picture";
import "./styles.scss";
import { ImageInterface } from "../../types/SanityTypes";
export interface HomePageBannerInterface {
  title: string;
  description?: string;
  isThisHomePage: boolean;
  image?: ImageInterface;
  mobileImage?: ImageInterface;
  ctaLabel?: string;
  externalLink?: string;
  internalLink?: string;
  bgColor: {
    title: string;
    value: string;
  };
  slug?: string;
  preferPerformance?: boolean;
  contentAlignment?: string;
}

const HomePageBanner: FunctionComponent<HomePageBannerInterface> = ({
  title,
  image,
  isThisHomePage,
  mobileImage,
  description,
  ctaLabel,
  externalLink,
  internalLink,
  bgColor,
  contentAlignment,
  preferPerformance = false
}) => {
  const { sanityId, sanityDataset } = useSiteMetadata();
  const urlBuilder = sanityImageUrlBuilder({
    projectId: sanityId,
    dataset: sanityDataset
  });

  const themeColor = bgColor?.title?.toLowerCase();
  const bannerImage = {
    src: `${image?.asset?.url}`,
    alt: `${image?.alt}`,
    sources: [
      {
        srcset: [
          `${urlBuilder.image(mobileImage).width(450).height(330).auto("format")?.url()} 1x`,
          `${urlBuilder.image(mobileImage).width(900).height(660).auto("format")?.url()} 2x`
        ],
        media: `(max-width: 767px)`
      },
      {
        srcset: [
          `${urlBuilder.image(image).width(768).height(420).auto("format")?.url()} 1x`,
          `${urlBuilder.image(image).width(1536).height(840).auto("format")?.url()} 2x`
        ],
        media: `(min-width: 768px) and (max-width: 1023px)`
      },
      {
        srcset: [
          `${urlBuilder.image(image).width(1400).height(620).auto("format")?.url()} 1x`,
          `${urlBuilder.image(image).width(2800).height(1240).auto("format")?.url()} 2x`
        ],
        media: `(min-width: 1024px) and (max-width: 1439px)`
      },
      {
        srcset: [
          `${urlBuilder.image(image).width(1600).height(700).auto("format")?.url()} 1x`,
          `${urlBuilder.image(image).width(3200).height(1400).auto("format")?.url()} 2x`
        ],
        media: `(min-width: 1440px)`
      }
    ],
    maintainAspectRatio: false,
    size: {
      width: 572,
      height: 404
    },
    lqip: image?.asset?.metadata?.lqip
  };

  return (
    <Card className={`c-home-page-banner rounded-0 border-0`}>
      {image && (
        <Card.Header className="p-0 border-0 rounded-0">
          <Picture
            src={bannerImage.src}
            alt={bannerImage.alt}
            sources={bannerImage.sources}
            maintainAspectRatio={bannerImage.maintainAspectRatio}
            size={bannerImage.size}
            lqip={bannerImage.lqip}
            inView={true}
            loading={preferPerformance ? "eager" : "lazy"}
            fetchPriority="high"
          />
        </Card.Header>
      )}
      <Card.ImgOverlay>
        <section
          className={`c-home-page-banner__content 
          ${isThisHomePage ? "c-home-page-banner__content__golden" : ""} 
          c-home-page-banner__content--${themeColor} 
          c-home-page-banner--position-${contentAlignment}`}
        >
          {title && <h1 className="mb-0">{title}</h1>}
          {description && <Card.Text>{description}</Card.Text>}
          {externalLink && ctaLabel && (
            <Link
              to={externalLink}
              asButton={themeColor}
              className="d-inline-block text-uppercase"
              type="external"
              rel="noreferrer"
              title="Links opens in a new window"
              data-analytics-event78
              aria-label={ctaLabel}
            >
              {ctaLabel}
            </Link>
          )}
          {internalLink && ctaLabel && (
            <Link _id={title} asButton={themeColor} to={internalLink} className="d-inline-block text-uppercase">
              {ctaLabel}
            </Link>
          )}
        </section>
      </Card.ImgOverlay>
    </Card>
  );
};

export default HomePageBanner;
